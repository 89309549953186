import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import theme from './components/CustomTheme';
import Header from "./components/Header";
import PdfVisualizer from "./components/PdfVisualizer";
import About from "./components/About.js";
import Bottom from "./components/Bottom";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Container maxWidth="lg">
          <div style={{minHeight: '35px'}}></div>
          <Header/>
          <div style={{minHeight: '35px'}}></div>
          <Switch>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/">
              <PdfVisualizer/>
            </Route>
          </Switch>
          <Bottom/>
          <Footer/>
        </Container>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;

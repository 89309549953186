import React from 'react';
import { Icon } from "@material-ui/core";
import IssuLogo from '../icons/Bitmap.svg';

function IssuIcon(props) {
    return (
        <span className="more-icon">
        <Icon>
            <img src={IssuLogo} height={24} width={24} alt="Issu Logo"/>
        </Icon>
        </span>
    )
}

export default IssuIcon;
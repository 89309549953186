import React, { Component } from 'react';
import { Map, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import db from './Firestore';

class DownloadMap extends Component {

    constructor(props) {
        super(props);
        this.state = {circleMarkers: ""};
    }

    componentDidMount() {
        const coords = require('country-coords');
        const countries = require('iso-3166-1-codes');
        var docRef = db.collection("counters").doc("downloadCountries");

        docRef.onSnapshot(((doc) => {
            if (doc.exists) {
                const geoCoords = Object.entries(doc.data()).map(element => {
                    const geoData = coords.byCountry().get(element[0]);
                    const name = countries.byAlpha2().get(element[0]).name;
                    return (<CircleMarker center={[geoData.latitude, geoData.longitude]} 
                                        radius={Math.min(element[1]/20,5)}
                                        color='white'>
                                <Popup>{element[1]} downloads in {name}!</Popup>
                            </CircleMarker>
                                            )
                });
                this.setState({circleMarkers: geoCoords});
            }
        }));
    }

    render(props) {
        const position = [25, 15]
        const map = (
            <Map center={position}
                zoom={0.5}
                zoomControl={false}
                boxZoom={false}
                dragging={true}
                touchZoom={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                keyboard={false}
                tap={false}>
            <TileLayer
                url='https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png'
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {this.state.circleMarkers}
            </Map>
        )
        return (
            <React.Fragment>
                <div className="map-title">Visits</div>
                <div id="mapId" className="map-container">
                    {map}
                </div>
            </React.Fragment>
        )
    }
}

export default DownloadMap;
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

class Print extends Component {

    render() {
        return (
            <Grid container item xs={4} lg={2} alignItems="flex-start">
                <Grid item xs={12}>
                    <div className="bottom-title">Print</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="bottom-strong">Yourself</div>
                    <div className="bottom-strong">Amazon</div>
                    <div className="bottom-strong"><a href="mailto:alejandro.soffia@gmail.com">HQ Copy</a></div>
                </Grid>
            </Grid>
        )
    }

}

export default Print;

const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

 const config = {
    apiKey: "AIzaSyD3hesDOZV5Y37tYg6ysBY6RlGxnuR2hek",
    authDomain: "webarquitecturapopular.firebaseapp.com",
    databaseURL: "https://webarquitecturapopular.firebaseio.com",
    projectId: "webarquitecturapopular",
    storageBucket: "webarquitecturapopular.appspot.com",
    messagingSenderId: "646483644775",
    appId: "1:646483644775:web:43d4252a47706e9d0fdfc2",
    measurementId: "G-3QB7R6558X"
  };

  firebase.initializeApp(config);
  var db = firebase.firestore();
  export default db;
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import InstagramIcon from './InstagramIcon';
import WebPageIcon from './WebPageIcon';
import FacebookIcon from './FacebookIcon';
import IssuIcon from './IssuIcon';
import MailIcon from './MailIcon';

class More extends Component {

    render() {
        return (
            <Grid container item xs={8} lg={6} alignItems="flex-start">
                <Grid item xs={12}>
                    <div className="bottom-title">More</div>
                </Grid>

                <Grid item xs={2} md={1}>
                    <MailIcon/>
                </Grid>
                <Grid item xs={10} md={5}>
                    <a href="mailto:alejandro.soffia@gmail.com">alejandro.soffia@gmail.com</a>
                </Grid>

                <Grid item xs={2} md={1}>
                    <WebPageIcon/>
                </Grid>
                <Grid item xs={4} md={5}>
                    Web page
                </Grid>
                
                <Grid item xs={2} md={1}>
                    <InstagramIcon/>
                </Grid>
                <Grid item xs={4} md={5}>
                    Instagram
                </Grid>

                <Grid item xs={2} md={1}>
                    <FacebookIcon/>
                </Grid>
                <Grid item xs={4} md={5}>
                    Facebook
                </Grid>

                <Grid item xs={2} md={1}>
                    <IssuIcon/>
                </Grid>
                <Grid item xs={4} md={5}>
                    Issu
                </Grid>

                
                
            </Grid>
        )
    }

}

export default More;

import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import DownloadMap from './DownloadMap';

class About extends Component {

    render() {
        return (
        <React.Fragment>
        <Hidden mdDown>
            <div className='about-space'>
                <div className='about-inside'>
                    <div className="breadcrumb-wrap">
                        <Link to="/" className="breadcrumb-home">Home</Link>
                        <span className="breadcrumb-strong"> > About</span>
                    </div>
                    <Grid container>
                        <Grid container item sm={8}>
                            <Grid item sm={6}>
                                <div className="english-about-title">Book</div>
                                <div className="english-about-content">
                                    This is not a book about Popular Architecture. That would be a
                                    massive topic... Instead, this book summarizes my search for
                                    Architecture to “be” popular. That is, I try to make my designs
                                    available to everyone. Because they are simple, cheap, and
                                    can be produced many times. Because I believe architectural
                                    design has virtues that can make people's lives more
                                    pleasant, and there should be no barriers to that.</div>
                            </Grid>
                            <Grid item sm={6}>
                            <div className="spanish-about-title">Libro</div>
                            <div className="spanish-about-content">
                                Éste no es un libro sobre la Arquitectura Popular. Que sería
                                un gran tema... En cambio, éste libro resume mi búsqueda
                                para que la Arquitectura “sea” popular. Es decir, yo trato que
                                mis diseños puedan estar al alcance de todos. Porque son
                                sencillos, económicos y reproducibles en serie.
                                Porque creo que el diseño arquitectónico tiene virtudes que
                                pueden hacerle más agradable la vida a las personas, y no
                                debiesen existir barreras para aquello.
                            </div>
                            </Grid>
                            <Grid item sm={6}>
                            <div className="english-about-title">Bio</div>
                            <div className="english-about-content">
                                Alejandro Soffia have been developing long term R+D since
                                2002. He focus on material optimization and prefabrication,
                                as a way to improve buildings impact on the environment, and
                                give access to good design, to a wider range of population.<br/>
                                Projects have been done in different scales and destination,
                                but they have been focused on materials, techniques and
                                details. This sort of interest have been a perfect complement
                                in designing building systems, even though this systems not
                                always are prefabricated, but give design flexibility to their
                                users.
                            </div>
                            </Grid>
                            <Grid item sm={6}>
                            <div className="spanish-about-title">Bio</div>
                            <div className="spanish-about-content">
                                Ha enfocado su trabajo en el desarrollo de I+D desde el año
                                2002. Sus proyectos arquitectónicos los desarrolla desde
                                tres estrategias: las bajas tecnologías, la optimización
                                material y la reducción estructural. De ésta manera se
                                aseguran los beneficios del diseño arquitectónico para un
                                rango mayor de la población.<br/>
                                Ha trabajado en proyectos de Arquitectura de distintas
                                escalas y programas, pero enfocado principalmente en las
                                capacidades de los materiales, las técnicas y los detalles
                                constructivos.
                            </div>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <DownloadMap/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Hidden>
        <Hidden lgUp>
            <div className="breadcrumb-wrap">
                <Link to="/" className="breadcrumb-home">Home</Link>
                <span className="breadcrumb-strong"> > About</span>
            </div>
            <Grid container>
                <Grid container item sm={12}>
                    <Grid item sm={12} style={{width: "100%"}}>
                        <DownloadMap/>
                    </Grid>
                    <Grid item sm={12}>
                        <div className="english-about-title">Book</div>
                        <div className="english-about-content">
                            This is not a book about Popular Architecture. That would be a
                            massive topic... Instead, this book summarizes my search for
                            Architecture to “be” popular. That is, I try to make my designs
                            available to everyone. Because they are simple, cheap, and
                            can be produced many times. Because I believe architectural
                            design has virtues that can make people's lives more
                            pleasant, and there should be no barriers to that.</div>
                    </Grid>
                    <Grid item sm={12}>
                    <div className="english-about-title">Bio</div>
                    <div className="english-about-content">
                        Alejandro Soffia have been developing long term R+D since
                        2002. He focus on material optimization and prefabrication,
                        as a way to improve buildings impact on the environment, and
                        give access to good design, to a wider range of population.<br/>
                        Projects have been done in different scales and destination,
                        but they have been focused on materials, techniques and
                        details. This sort of interest have been a perfect complement
                        in designing building systems, even though this systems not
                        always are prefabricated, but give design flexibility to their
                        users.
                    </div>
                    </Grid>
                    <Grid item sm={12}>
                    <div className="spanish-about-title">Libro</div>
                    <div className="spanish-about-content">
                        Éste no es un libro sobre la Arquitectura Popular. Que sería
                        un gran tema... En cambio, éste libro resume mi búsqueda
                        para que la Arquitectura “sea” popular. Es decir, yo trato que
                        mis diseños puedan estar al alcance de todos. Porque son
                        sencillos, económicos y reproducibles en serie.
                        Porque creo que el diseño arquitectónico tiene virtudes que
                        pueden hacerle más agradable la vida a las personas, y no
                        debiesen existir barreras para aquello.
                    </div>
                    </Grid>
                    <Grid item sm={12}>
                    <div className="spanish-about-title">Bio</div>
                    <div className="spanish-about-content">
                        Ha enfocado su trabajo en el desarrollo de I+D desde el año
                        2002. Sus proyectos arquitectónicos los desarrolla desde
                        tres estrategias: las bajas tecnologías, la optimización
                        material y la reducción estructural. De ésta manera se
                        aseguran los beneficios del diseño arquitectónico para un
                        rango mayor de la población.<br/>
                        Ha trabajado en proyectos de Arquitectura de distintas
                        escalas y programas, pero enfocado principalmente en las
                        capacidades de los materiales, las técnicas y los detalles
                        constructivos.
                    </div>
                    </Grid>
                </Grid>
            </Grid>        
        </Hidden>
        </React.Fragment>
        )
    }

}

export default About;
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link, useLocation } from "react-router-dom";

import VisitorCounter from './VisitorCounter';

function Header(props) {
    const location = useLocation().pathname;

    return (
        <div>
            <Hidden mdDown>
                <Grid container>
                    <Grid item xs={8}>
                        <span className="header-arquitectura">Arquitectura </span>
                        <span className="header-popular">Popular Architecture </span>
                        <span className="header-by">by Alejandro Soffia</span>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={1}>
                        <VisitorCounter/>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={1}>
                        <div className="relative-div">
                            <Link to="/about" className={location === "/about" ? "header-about-selected-small" : "header-about-small" }>About</Link>
                        </div>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <Grid container>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <span className="header-arquitectura">Arquitectura </span>
                        </Grid>
                        <Grid item xs={12}>
                        <span className="header-popular">Popular Architecture </span>
                        </Grid>
                        <Grid item xs={12}>
                        <span className="header-by">by Alejandro Soffia</span>
                        </Grid>
                        <Grid item xs={12}>
                            <VisitorCounter cssType="small"/>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="relative-div">
                            <Link to="/about" className={location === "/about" ? "header-about-selected-small" : "header-about-small" }>About</Link>
                        </div>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    )

}

export default Header;
import React, { Component } from 'react';
import db from './Firestore';

class VisitorCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
         visits: "Loading...",
         addedCount: false,
        };
      }

    componentDidMount() {
        var docRef = db.collection("counters").doc("countersDoc");

        docRef.onSnapshot(((doc) => {
            if (doc.exists) {
                this.setState({visits: doc.data().visits})
                if (!this.state.addedCount) {
                    this.setState({addedCount: true});
                    docRef.set({visits: doc.data().visits + 1}, {merge: true});
                }
            }
        }));
    }

    render () {
        return (
            <React.Fragment>
                <div className="relative-div">
                    <span className={this.props.cssType==="small" ? "visits-number-small" : "visits-number"}>
                        {this.state.visits==="Loading..." ? "" : "V"}{this.state.visits.toString().padStart(5,'0')}
                    </span>
                </div>
            </React.Fragment>
        );
    }
}

export default VisitorCounter;
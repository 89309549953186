import React, { Component } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Iframe from 'react-iframe';

class PdfVisualizer extends Component {

    render() {
        return (
        <div className="pdf-container">
            <Hidden mdDown>
                <div className="pdf-viewer-div">
                    <Iframe className="pdf-iframe" src="external/pdf-flipbook/pdf-index.html?spread=True#page=2"/>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div className="pdf-viewer-div-small">
                    <Iframe className="pdf-iframe" src="external/pdf-flipbook/pdf-index.html#page=3"/>
                </div>
            </Hidden>
        </div>
        )
    }

}

export default PdfVisualizer;
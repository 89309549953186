import React from 'react';
import { Icon } from "@material-ui/core";
import InstagramLogo from '../icons/instagram.svg';

function InstagramIcon(props) {
    return (
        <span className="more-icon">
            <Icon>
                <img src={InstagramLogo} height={24} width={24} alt="Instagram Logo"/>
            </Icon>
        </span>
    )
}

export default InstagramIcon;
import React from 'react';
import { Icon } from "@material-ui/core";
import WebPageLogo from '../icons/globe.svg';

function WebPageIcon(props) {
    return (
        <span className="more-icon">
            <Icon>
                <img src={WebPageLogo} height={24} width={24} alt="WebPage Logo"/>
            </Icon>
        </span>
    )
}

export default WebPageIcon;
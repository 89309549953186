import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class Footer extends Component {

    render() {
        return (
        <div>
            <Typography align='center'>
                <div className="footer">
                    Popular architecture 2020 ©
                </div>
            </Typography>
        </div>
        )
    }

}

export default Footer;
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Hidden from '@material-ui/core/Hidden';
import Print from './Print';
import More from './More';

class Bottom extends Component {

    render() {
        return (
            <React.Fragment>
                <Hidden mdDown>
                    <Grid container alignItems="flex-start" className="bottom-part-offset">
                        <Grid container item xs={0} lg={3} alignItems="flex-start">
                        </Grid>
                        <Grid item xs={0} lg={1}></Grid>
                        <Print/>
                        <More/>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Grid container alignItems="flex-start">
                    <Grid container item xs={0} lg={3} alignItems="flex-start">
                    </Grid>
                    <Grid item xs={0} lg={1}></Grid>
                    <Print/>
                    <More/>
                </Grid>
                </Hidden>
            </React.Fragment>
        )
    }

}

export default Bottom;
import { createMuiTheme } from '@material-ui/core/styles';

import HelveticaNowRegularWoff from '../fonts/HelveticaNowText-Regular.woff';
import HelveticaNowLightWoff from '../fonts/HelveticaNowText-Light.woff';
import HelveticaNowBoldWoff from '../fonts/HelveticaNowText-Bold.woff';
import HelveticaNowMediumWoff from '../fonts/HelveticaNowText-Medium.woff';

const helveticaNowRegular = {
    fontFamily: 'HelveticaNow',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${HelveticaNowRegularWoff}) format('woff')`,
};

const helveticaNowMedium = {
    fontFamily: 'HelveticaNow',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `url(${HelveticaNowMediumWoff}) format('woff')`,
};

const helveticaNowLight = {
    fontFamily: 'HelveticaNow',
    fontStyle: 'normal',
    fontWeight: 200,
    src: `url(${HelveticaNowLightWoff}) format('woff')`,
};

const helveticaNowBold = {
    fontFamily: 'HelveticaNow',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `url(${HelveticaNowBoldWoff}) format('woff')`,
};

const theme = createMuiTheme({
    typography: {
        fontFamily: ['HelveticaNow']
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [helveticaNowRegular, helveticaNowLight, helveticaNowBold, helveticaNowMedium],
            }
        }
    }
})

export default theme;